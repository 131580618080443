define('ember-computed-style/computed-style', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computedStyle = undefined;
  var keys = Object.keys;


  /**
   * Lifted from Ember Metal Computed Macros
   */

  function getProperties(self, propertyNames) {
    var ret = {};
    for (var i = 0; i < propertyNames.length; i++) {
      ret[propertyNames[i]] = Ember.get(self, propertyNames[i]);
    }
    return ret;
  }

  function generateComputedWithProperties(macro) {
    return function () {
      for (var _len = arguments.length, properties = Array(_len), _key = 0; _key < _len; _key++) {
        properties[_key] = arguments[_key];
      }

      var computedFunc = Ember.computed(function () {
        return macro.apply(this, [getProperties(this, properties)]);
      });

      return computedFunc.property.apply(computedFunc, properties);
    };
  }

  // Lifted from React
  var isUnitlessNumber = {
    animationIterationCount: true,
    boxFlex: true,
    boxFlexGroup: true,
    boxOrdinalGroup: true,
    columnCount: true,
    flex: true,
    flexGrow: true,
    flexPositive: true,
    flexShrink: true,
    flexNegative: true,
    flexOrder: true,
    gridRow: true,
    gridColumn: true,
    fontWeight: true,
    lineClamp: true,
    lineHeight: true,
    opacity: true,
    order: true,
    orphans: true,
    tabSize: true,
    widows: true,
    zIndex: true,
    zoom: true,

    // SVG-related properties
    fillOpacity: true,
    stopOpacity: true,
    strokeDashoffset: true,
    strokeOpacity: true,
    strokeWidth: true
  };

  /**
   * @param {string} prefix vendor-specific prefix, eg: Webkit
   * @param {string} key style name, eg: transitionDuration
   * @return {string} style name prefixed with `prefix`, properly camelCased, eg:
   * WebkitTransitionDuration
   */
  function prefixKey(prefix, key) {
    return prefix + key.charAt(0).toUpperCase() + key.substring(1);
  }

  /**
   * Support style names that may come passed in prefixed by adding permutations
   * of vendor prefixes.
   */
  var prefixes = ['Webkit', 'ms', 'Moz', 'O'];

  // Using keys here, or else the vanilla for-in loop makes IE8 go into an
  // infinite loop, because it iterates over the newly added props too.
  keys(isUnitlessNumber).forEach(function (prop) {
    prefixes.forEach(function (prefix) {
      isUnitlessNumber[prefixKey(prefix, prop)] = isUnitlessNumber[prop];
    });
  });

  function transformStyleValue(name, value) {
    if (Ember.isEmpty(value)) {
      return '';
    }

    var isNonNumeric = isNaN(value);
    var valueTransformed = value;
    if (isNonNumeric || value === 0 || isUnitlessNumber.hasOwnProperty(name) && isUnitlessNumber[name]) {
      return '' + value; // cast to string
    }

    if (typeof value === 'string') {
      valueTransformed = value.trim();
    }
    return valueTransformed + 'px';
  }

  function objectToStyleString(object) {
    return keys(object).map(function (name) {
      var value = transformStyleValue(name, object[name]);
      if (Ember.isEmpty(value)) {
        return null;
      }
      return (0, _string.dasherize)(name) + ':' + value;
    }).filter(function (rule) {
      return rule !== null;
    }).join(';');
  }

  /**
   * Computes a style string from the value of bound properties.
   */
  var computedStyle = exports.computedStyle = generateComputedWithProperties(function computedStyleProperties(properties) {
    var styleStrings = [];

    keys(properties).forEach(function (dependentKey) {
      styleStrings.push(objectToStyleString(properties[dependentKey]));
    });

    var styleString = styleStrings.join(';');

    if (styleString.length > 1 && styleString.charAt(styleString.length - 1) !== ';') {
      styleString += ';';
    }

    return Ember.String.htmlSafe(styleString);
  });
});