define("ember-composable-helpers/helpers/append", ["exports", "ember-composable-helpers/-private/create-multi-array-helper"], function (_exports, _createMultiArrayHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function append() {
    var _Ember;
    for (var _len = arguments.length, dependentKeys = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }
    dependentKeys = dependentKeys || [];
    var arrayKeys = dependentKeys.map(function (dependentKey) {
      return "".concat(dependentKey, ".[]");
    });
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(arrayKeys).concat([function () {
      var _this = this,
        _ref;
      var array = dependentKeys.map(function (dependentKey) {
        var value = Ember.get(_this, dependentKey);
        return Ember.isArray(value) ? value.toArray() : [value];
      });
      return (_ref = []).concat.apply(_ref, _toConsumableArray(array));
    }]));
  }
  var _default = _exports.default = (0, _createMultiArrayHelper.default)(append);
});