define("ember-composable-helpers/-private/create-multi-array-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _toArray(r) { return _arrayWithHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  var idForArray = function idForArray(array) {
    return "__array-".concat(Ember.guidFor(array));
  };
  function _default(multiArrayComputed) {
    return Ember.Helper.extend({
      compute: function compute(_ref) {
        var _ref2 = _toArray(_ref),
          arrays = _ref2.slice(0);
        Ember.set(this, 'arrays', arrays.map(function (obj) {
          if (Ember.isArray(obj)) {
            return Ember.A(obj);
          }
          return obj;
        }));
        return Ember.get(this, 'content');
      },
      valuesDidChange: Ember.observer('arrays.[]', function () {
        this._recomputeArrayKeys();
        var arrays = Ember.get(this, 'arrays');
        var arrayKeys = Ember.get(this, 'arrayKeys');
        if (Ember.isEmpty(arrays)) {
          Ember.defineProperty(this, 'content', []);
          return;
        }
        Ember.defineProperty(this, 'content', multiArrayComputed.apply(void 0, _toConsumableArray(arrayKeys)));
      }),
      contentDidChange: Ember.observer('content.[]', function () {
        this.recompute();
      }),
      _recomputeArrayKeys: function _recomputeArrayKeys() {
        var _this = this;
        var arrays = Ember.get(this, 'arrays');
        var oldArrayKeys = Ember.get(this, 'arrayKeys') || [];
        var newArrayKeys = arrays.map(idForArray);
        var keysToRemove = oldArrayKeys.filter(function (key) {
          return newArrayKeys.indexOf(key) === -1;
        });
        keysToRemove.forEach(function (key) {
          return Ember.set(_this, key, null);
        });
        arrays.forEach(function (array) {
          return Ember.set(_this, idForArray(array), array);
        });
        Ember.set(this, 'arrayKeys', newArrayKeys);
      }
    });
  }
});